<template>
    <div>
        <div class="bg-primary-950 py-4 text-center text-gray-300 text-sm w-full">
            <!-- <div class="flex justify-center">
              <div class="align-bottom">
                <img
                  width="92"
                  src="https://app.cesessions.org/assets/images/MLI-Logo-White-MEDIUM-1600x1600px-150x150.png"
                  alt=""
                />
              </div>
              <div class="ml-8 text-left">
                <p class="font-semibold">Medical Learning Institute, Inc.</p>
                <p class="text-sm font-thin">
                  40946 US Highway 19N, #602<br />
                  Tarpon Springs, FL, 34689<br />
                  <a href="tel:8886542631">888.654.2631</a> (toll-free)<br />
                  <a href="tel:6093331693">609.333.1693</a> (direct)<br />
                </p>
              </div>
            </div> -->
            <div class="font-semibold text-md mt-4">
              © 2023 KIME Global, LLC. All rights reserved.
            </div>
        </div>
    </div>
</template>

<script> 
export default {
  name: 'Footer'
}
</script>