
  import router from "@/router";
  import User from "@/types/User";
  import { defineComponent, reactive, onMounted } from "vue";
   export default defineComponent({
      name:"course-view",
      setup() { 
          interface State { 
              token: string | null,
              showMenu: boolean
          }

          const state:State = reactive({
              token: null,
              showMenu: false
          });

          const authChecked = () => { 
            const user = JSON.parse(window.localStorage.getItem('user') || '{}') as User;
            state.token = user.token
          }

          const userLogout = () => { 
            window.localStorage.removeItem('user')
            window.location.href = '/';//this line is incorrect.to be fixed later.
          }

          const manuResponse = () => {
            state.showMenu = !state.showMenu
            console.log("click",!state.showMenu);
          }

          onMounted(() => {
              authChecked()
          });

          return {state,userLogout,manuResponse}
      } 
  })
